<template>
  <CContainer class="d-flex flex-row content-center text-left min-vh-100">
    <CRow class="align-items-center w-75">
      <CCol sm="12">
        <CCardGroup>
          <CCard class="p-4">
            <loader :loading="requisicaoAberta"></loader>
            <CCardBody>
              <CForm>
                <h1>Acessar</h1>
                <p class="text-muted">Entre com o e-mail cadastrado!</p>
                <CInput placeholder="E-mail" autocomplete="email" v-model="usuario.email">
                  <template #prepend-content>
                    <CIcon name="cil-user" />
                  </template>
                </CInput>
                <CInput
                  placeholder="senha"
                  type="password"
                  autocomplete="curent-password"
                  v-model="usuario.senha"
                  @keypress.enter="login"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                  </template>
                </CInput>
                <CAlert color="warning" v-if="senhaInvalida">E-mail ou senha Inválida!</CAlert>
                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton color="primary" class="px-4" @click="login">Entrar</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">Esqueceu a senha?</CButton>
                    <CButton color="link" class="d-md-none">Registrar</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard class="text-center py-5 d-sm-down-none" body-wrapper>
            <h2 class="pb-4">
              <CIcon name="logo" size="custom-size" :height="45" viewBox="0 0 630.11 174.77" scale />
            </h2>
            <p>Não é registrado? Aproveite e registre agora!!</p>
            <CButton color="primary" class="active mt-3">Registrar</CButton>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import Axios from "axios";
import Meta from "./../../shared/meta-dados";
import Loader from "./../Componentes/loader";
export default {
  name: "Login",
  components: { Loader },

  data: () => {
    return {
      usuario: {
        email: "",
        senha: ""
      },
      senhaInvalida: false,
      requisicaoAberta: false
    };
  },
  methods: {
    login() {
      this.requisicaoAberta = true;
      Axios.post("/usuario/login", this.usuario)
        .then(response => {
          if (response.data.retorno.token) {
            this.$sessionStorage.set("autorizacao", response.data.retorno);
            Axios.defaults.headers.common["Authorization"] =
              response.data.retorno.token;

            Meta.obtenhaMetadados();
            this.$router.push({ name: "Home" });
          } else {
            this.senhaInvalida = true;
          }
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  created() {
    if (this.$route.name == "Logout") {
      this.$sessionStorage.clear();
    }
  }
};
</script>
